<template>
  <div class="pilot-schedule-calendar" :class="[loading ? 'loading' : '']">
    <vc-calendar
      class="custom-calendar max-w-full"
      :masks="vcBase.masks"
      :attributes="attributes"
      :columns="$screens({ default: 1, lg: 1 })"
      :rows="1"
      :step="1"
      :first-day-of-week="2"
      trim-weeks
      disable-page-swipe
      is-expanded
      @update:from-page="fromPage"
      @update:to-page="toPage"
    >
      <template v-slot:day-content="{ day, attributes }">
        <div class="custom-day" @click="dayclick(day)">
          <div class="day">
            {{ day.day }}
          </div>
          <div
            v-for="(attr, iattr) in attributes"
            :key="'attr-pilot-' + iattr"
            :class="[$PilotScheduleStatus(attr.customData.status, $store.state.pilot_status).color]"
            class="custom-day-info text-center"
          >
            <div v-if="attr.customData.base_color !== '#FFFFFF'" class="base-color" :style="`background-color: ${attr.customData.base_color}`"></div>
            <span v-if="parseInt(attr.customData.count_logbooks) !== parseInt(attr.customData.count_schedules)" class="count-status" :class="[$dayStatus(attr.customData)]">
              {{ (attr.customData.count_schedules || 0) - (attr.customData.count_logbooks || 0) }}
            </span>
            <span v-else class="count-status" :class="[$dayStatus(attr.customData)]">
              &check;
            </span>
            <div class="d-inline-block fill-width text-truncate" style="max-width:50px;">
              {{ (
                $PilotScheduleStatus(attr.customData.status, $store.state.pilot_status).sn === 'DUTY' ||
                $PilotScheduleStatus(attr.customData.status, $store.state.pilot_status).sn === 'TX' ||
                $PilotScheduleStatus(attr.customData.status, $store.state.pilot_status).sn === 'FER'
                ? (attr.customData.base_name ? attr.customData.base_name : $PilotScheduleStatus(attr.customData.status, $store.state.pilot_status).sn)
                : $PilotScheduleStatus(attr.customData.status, $store.state.pilot_status).sn
              ) }}
            </div>
          </div>
        </div>
      </template>
      <template #day-popover="{ day, format, masks }">
        <div class="text-xs text-gray-300 font-semibold text-center">
          {{ format(day.date, masks.dayPopover) }}
          <div
            v-for="{key, customData} in attributes"
            :key="key"
            v-show="day.id === $localDT(customData.duty_date, 'datedefault')"
            class="day-schedule-popover"
          >
            <flex class="flex-space-beetween">
              <span class="name">
                {{ customData.base_name || '-' }}
              </span>
              <span v-if="parseFloat(customData.ft)" class="ft">
                {{ customData.ft }}
              </span>
              <span class="status" :style="{ backgroundColor: $PilotScheduleStatus(customData.status, $store.state.pilot_status).color }">
                {{ $PilotScheduleStatus(customData.status, $store.state.pilot_status).name }}
              </span>
            </flex>
          </div>
        </div>
      </template>
    </vc-calendar>

    <section v-if="!showListBase" class="calendar-legends-container">
      <div class="font-18 fw-600">
        Informations :
      </div>
      <div class="mt-3">
        <v-row v-for="(data, index) in ($store.state.pilot_status).filter(r => r.status !== 0)" no-gutters :key="index" class="mb-2">
          <v-col cols="3" class="align-self-center">
            <div class="color-code" :class="data.color">
              {{ data.sn }}
            </div>
          </v-col>
          <v-col cols="1" class="align-self-center text-center">
            :
          </v-col>
          <v-col cols="8" class="align-self-center">
            {{ data.name }}
          </v-col>
        </v-row>
      </div>
    </section>

    <section v-if="showListBase" class="base-code-container">
      <div @click.prevent="toggleBaseContainer(false)" class="base-code-overlay animate__animated animate__fadeIn animate__faster"></div>
      <v-card class="base-code-card animate__animated animate__slideInUp animate__faster">
        <div class="font-18 fw-600">
          Base Name
        </div>

        <v-text-field
          v-model="searchBase"
          placeholder="Code / Full Name / Area Name"
          prepend-inner-icon="mdi-magnify"
          color="primary"
          hide-details
          class="select-input-logbook mb-4" />

        <div class="responsive-base-table">
          <v-data-table
            :headers="baseHeaders"
            :items="baseData"
            :items-per-page="25"
            :expanded.sync="expanded"
            sort-by="name_short"
            :search="searchBase"
            hide-default-footer
            item-key="id"
            show-expand
            fixed-header
            mobile-breakpoint="0"
            :page.sync="basePage"
            @page-count="basePageCount = $event"
          >
            <template v-slot:item.name_short="{ item }">
              <span class="align-middle">
                {{ item.name_short || '-' }}
              </span>
              <div v-if="item.base_color !== '#FFFFFF'" class="base-color-list align-middle ml-2" :style="`background-color: ${item.base_color}`"></div>
            </template>
            <template v-slot:item.name="{ item }">
              {{ item.name || '-' }}
            </template>

            <!-- Hidden Column (For searching data purposes only) -->
            <template v-slot:item.area_name="{}">
              {{ '' }}
            </template>
            <!-- End of hidden column -->

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="text-left py-3">
                <v-row no-gutters>
                  <v-col cols="6" class="align-self-center mb-3">
                    <div class="font-13 fw-500">
                      Area Name :
                    </div>
                    <div class="font-14">
                      {{ item.area_name }}
                    </div>
                  </v-col>
                  <v-col cols="6" class="align-self-center mb-3">
                    <div class="font-13 fw-500">
                      Maintenance Base ?
                    </div>
                    <div class="font-14" :class="item.maintenance_base ? 'success--text' : 'error--text'">
                      {{ item.maintenance_base ? 'Yes' : 'No' }}
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div class="font-13 fw-500">
                      Coordinate :
                    </div>
                    <div v-if="item.pos_latitude && item.pos_longitude" class="font-14" @click.prevent="item.pos_latitude && item.pos_longitude ? OpenURL('https://www.google.com/maps/@' + item.pos_latitude + ',' + item.pos_longitude + ',1077m/data=!3m1!1e3') : ''">
                      <span class="align-middle">
                        {{ parseFloat(item.pos_latitude).toFixed(4) || '-' }}, {{ parseFloat(item.pos_longitude).toFixed(4) || '-' }}
                      </span>
                      <v-icon v-if="item.pos_latitude && item.pos_longitude" small class="align-middle ml-2">mdi-airplane-marker</v-icon>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
          <div v-if="basePageCount > 1" class="text-center pt-2">
            <v-pagination
              v-model="basePage"
              :length="basePageCount"
            ></v-pagination>
          </div>
        </div>
      </v-card>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    attributes: {
      type: Array,
      default: () => []
    }
  },
  data: vm => ({
    showListBase: false,
    searchBase: '',
    expanded: [],
    baseHeaders: [
      { text: 'Code', align: 'start', class: 'primary--text font-14', sortable: false, value: 'name_short' },
      { text: 'Name', align: 'start', class: 'primary--text font-14', sortable: false, value: 'name' },
      { text: '', class: 'd-none', value: 'area_name' },
      { text: '', value: 'data-table-expand' }
    ],
    baseData: [],
    basePage: 1,
    basePageCount: 0,
    vcBase: {
      masks: {
        weekdays: 'WW'
      },
      attributes: [
        {
          key: 'today',
          highlight: 'red',
          dates: new Date()
        }
      ]
    },
    page: {
      from: '',
      to: ''
    }
  }),
  computed: {
    startMonthDay: () => {
      return new Date(this.activeYearStart, this.activeMonthStart, 0).getDay()
    },
    startNextMonthDay: () => {
      return new Date(this.activeYearStart, this.startNextActiveMonth, 0).getDay()
    }
  },
  watch: {
    $route: function (to, from) {
      if (to) {
        this.$emit('update-page', { from: this.page.from, to: this.page.to })
      }
    }
  },
  methods: {
    toggleBaseContainer (v) {
      this.showListBase = v
      this.baseData = this.$store.state.base.all.data.data.filter(r => r.name_short)
      this.$emit('block-scroll', v)
    },
    dayclick (v) {
      this.$router.push('?scheduleDate=' + this.$localDT(v.date, 'datedefault'))
    },
    fromPage (e) {
      const from = e.year + '-' + (parseInt(e.month) < 10 ? '0' + e.month : e.month) + '-01'
      this.page.from = from
    },
    toPage (e) {
      const to = e.year + '-' + (parseInt(e.month) < 10 ? '0' + e.month : e.month) + '-01'
      const lastmonth = this.$getThisMonth(to)
      this.page.to = lastmonth.to
      this.$emit('update-page', { from: this.page.from, to: this.page.to })
    },
    OpenURL (link) {
      this.$SweetAlert({
        title: 'Leaving Forpilots?',
        caption: 'You will be redirected into external web sites.',
        confirmText: 'Continue',
        onConfirm: () => { window.open(link, 'parent') }
      })
    }
  }
}
</script>

<style lang="scss">
@import './../assets/scss/global-vars';
@import './../assets/scss/fonts';

.pilot-schedule-calendar {
  position: relative;
  &.loading {
    &:after {
      content: "Please Wait ...";
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 1;
      border-radius: 7px;
      color: #fff;
      background-color: rgba(0, 0, 0, .7);
    }
  }
  .vc-container{
    font-family: $font-family;
    background-color: $white;
    border: 0;
    border-radius: 50px 50px 0 0;
    width: 100vw;
    max-width: 500px;
    overflow-x: hidden;
    .vc-arrows-container{
      padding: 29px 30px 20px;
    }
    .vc-header{
      padding: 30px 30px 20px;
      border-bottom: 1px solid #F2F1FF;
      .vc-title{
        @include font-size(18px);
        font-weight: 700;
      }
    }
  }
  .is-today:not(.in-next-month) {
    background: transparent;
    border-bottom: 2px solid $primary;
    padding-top: 3px;
    width: 32px;
    margin-left: 10px;
    top: -3px;
    .day{
      color: $primary;
    }
  }
  .base-color{
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 28px;
    bottom: 21px;
    border-radius: 0 0 5px 5px;
    z-index: 0;
  }
  .base-color-list{
    display: inline-block;
    vertical-align: middle;
    width: 14px;
    height: 14px;
    border-radius: 4px;
    margin-left: 4px;
  }
  .vc-nav-popover-container{
    background-color: $primary;
    border-color: $primary;
    border-radius: 20px;
    padding: 16px;
    .vc-nav-title:hover{
      background-color: $primary;
    }
    .vc-nav-item.is-active{
      background-color: $danger;
      color: $white;
    }
  }
  .calendar-legends-container{
    padding: 30px 30px 0;
    text-align: left;
    .color-code{
      position: relative;
      padding: 4px;
      background: unset !important;
      background-color: unset !important;
      @include font-size(14px);
      font-weight: 600;
      padding-left: 34px;
      &::before{
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        width: 24px;
        height: 24px;
        border-radius: 5px;
      }
      &.primary {
        &::before {
          background: $primary;
        }
      }
      &.green {
        &::before {
          background: #13C999;
        }
      }
      &.black {
        &::before {
          border: solid 1px rgb(10, 5, 0);
          background: rgba(17, 7, 0, 1);
        }
      }
      &.red {
        &::before {
          background: $danger;
        }
      }
      &.orange {
        &::before {
          background: #FEC091;
        }
      }
      &.yellow {
        &::before {
          background: rgba(253, 209, 11, 1);
        }
      }
      &.grey {
        &.darken-2::before{
          background: rgb(90, 90, 90);
        }
        &::before {
          background: rgba(133, 133, 133, 1);
        }
      }
      &.purple {
        &::before {
          background: rgb(103, 21, 136);
        }
      }
      &.aqua {
        &::before {
          background: rgba(7, 137, 197, 1);
        }
      }
      &.brown {
        &::before {
          background: rgb(128, 57, 0);
        }
      }
    }
  }
}
.select-input-logbook{
  &.v-input--is-focused{
    .v-input__slot::after{
      transform: scaleX(.966);
    }
  }
  .v-input__slot{
    border: 1px solid #F9F9F9;
    border-radius: 10px;
    padding: 0 16px;
    &::before{
      border-color: transparent !important;
    }
  }
  .v-input__prepend-inner{
    margin-top: 0;
    align-self: center;
    .v-icon{
      &::before{
        @include font-size(22px);
      }
    }
  }
  .v-text-field__slot,
  .v-select__slot{
    input{
      @include font-size(13px);
    }
    .v-input__append-inner{
      .v-icon::before{
        color: $danger;
        @include font-size(22px);
      }
      &:last-of-type{
        display: none;
      }
    }
  }
}
.base-code-container{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 201;
  .base-code-overlay{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 202;
    background-color: rgba(0, 0, 0, .5);
  }
  .base-code-card{
    position: fixed;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    max-height: 85vh;
    min-height: 25vh;
    overflow: hidden;
    padding: 40px 30px 85px;
    text-align: left;
    border-radius: 50px 50px 0 0;
    background-color: $white;
    box-shadow: none !important;
    z-index: 203;
    .responsive-base-table{
      .v-data-table{
        background-color: #ededed;
        border-radius: 10px;
        overscroll-behavior: contain;
        thead > tr {
          th{
            background-color: #ededed;
          }
          >th:first-child{
            border-radius: 10px 0 0 0;
          }
          >th:last-child{
            border-radius: 0 10px 0 0;
          }
        }
        tbody > tr {
          >td:nth-child(3){
            display: none;
          }
        }
        .v-data-table__wrapper{
          height: calc(100vh - 420px);
        }
      }
      .v-pagination__item{
        background-color: $white;
        border-radius: 10px;
        &:not(.v-pagination__item--active){
          box-shadow: none;
        }
      }
      .v-pagination__navigation{
        border-radius: 40px;
      }
    }
  }
}
.day-schedule-popover {
  min-width: 150px;
  padding: 4px 4px 0px 4px;
  span {
    display: block;
    padding: 4px 8px;
    border-radius: 4px;
    border: solid 1px #ddd;
    line-height: 100%;
    font-size: 8px;
    &.status {
      color: #fff;
      border: solid 1px transparent;
    }
    &.ft {
      margin: 0px 2px;
    }
  }
}
.vc-weekday{
  @include font-size(18px);
  font-weight: 500 !important;
  color: $grey !important;
  padding-bottom: 18px;
}
.vc-day{
  margin: 6px 0;
  .custom-day {
    position: relative;
    cursor: pointer;
    >.day {
      &:has(+.custom-day-info:not(.transparent)){
        color: $white;
      }
      display: inline-block;
      @include font-size(18px);
      font-weight: 500;
      color: $grey;
    }
    >.custom-day-info {
      position: relative;
      font-size: 9px;
      padding-top: 7px;
      padding-bottom: 5px;
      background: none!important;
      line-height: 100%;
      >.count-status,
      &::after {
        position: absolute;
        text-align:center;
        content: "";
        top: -27px;
        left: calc(50% - 14px);
        right: auto;
        height: 28px;
        width: 28px;
        border-radius: 5px;
        z-index: -1;
      }
      >.count-status {
        display: none;
        top: -33px;
        padding: 3px 2px;
        text-align: center;
        font-size: 8px;
        left: calc(50% + 4px);
        color: #fff;
        z-index: 1;
        height: auto;
        width: 16px;
        font-weight:bold;
        &.green,
        &.red {
          display: block;
          border-radius:50%!important;
        }
      }
      &.green {
        &::after {
          background: #13C999 !important;
        }
      }
      &.black {
        &::after {
          border: solid 1px rgb(10, 5, 0);
          background: rgba(17, 7, 0, 1)!important;
        }
      }
      &.red {
        &::after {
          background: $danger !important;
        }
      }
      &.orange {
        &::after {
          background: #FEC091 !important;
        }
      }
      &.yellow {
        &::after {
          background: #fdd10b!important;
        }
      }
      &.grey {
        &::after {
          background: #858585!important;
        }
      }
      &.purple {
        &::after {
          background: #671588!important;
        }
      }
      &.aqua {
        &::after {
          background: #0789c5!important;
        }
      }
      &.brown {
        &::after {
          background: #803900!important;
        }
      }
    }
  }
}
.vc-weeks{
  padding: 10px 30px !important;
}
</style>
